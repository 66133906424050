<template>
	<a-card :bordered="false">
		<div class="table-page-search-wrapper">
			<a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }" @submit="handleSubmit">
			  <!--  <a-form-item label="名称">
			        <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入名称' }] }]" placeholder="请输入名称" />
			    </a-form-item>
				<a-form-item label="描述">
				    <a-textarea :rows="4" v-decorator="['remark', { rules: [{ required: true, message: '请输入描述' }] }]" placeholder="请输入描述" />
				</a-form-item>
				<a-form-item label="角色类型">
				    <a-select
				        v-decorator="['type',{ rules: [{ required: true, message: '请选择角色类型' }] }]"
				        placeholder="选择角色类型">
				        <a-select-option :value="item.id" v-for="(item,index) in roleType" :key="index">
				            {{item.title}}
				        </a-select-option>
				    </a-select>
				</a-form-item> -->
				<a-form-item label="功能模块">
					<a-checkbox @change="onChange" :checked="checked">选择全部功能模块</a-checkbox>
				</a-form-item>
				<a-form-item label="">
					<a-row>
						<a-col :span="4"></a-col>
						<a-col :span="19"> 
							<a-tree v-if="treeData.length" class="menuTree"
							    v-model="checkedKeys"
							    checkable
							    :expanded-keys="expandedKeys"
							    :selected-keys="selectedKeys"
							    :tree-data="treeData"
							    @expand="onExpand"
							    @select="onSelect"
								@check="onCheck"
								:show-line="showLine"
								:show-icon="showIcon"
								:replaceFields="replaceFields"
							/>
						</a-col>
					</a-row>
				</a-form-item>
				<a-form-item :wrapper-col="{ span: 24 }" style="text-align: center;">
				    <a-button type="primary" html-type="submit">确定</a-button>
				    <a-button type="default" style="margin-left: 10px;" @click="cancel">取消</a-button>
				</a-form-item>
			</a-form>
		</div>
	</a-card>
</template>

<script>
	import {
		menuList,
		bindAuth,
		getNodes
	} from '@/api/system'
	import router from '@/router'
	import store from '@/store'
	const loginRoutePath = '/user/login'
	import { getUrlKey } from '@/utils'
	export default {
		name: '',
		components: {
			
		},
		data() {
			return {
				form:this.$form.createForm(this),
				expandedKeys: ["1"],
				defaultExpandAll:true,
				autoExpandParent: true,
				checkedKeys: [],
				selectedKeys: [],
				treeData:[],
				showLine: true,
				showIcon: false,
				roleType:[
					{id:'1',title:'信息发布员'},
					{id:'2',title:'站点管理员'}
				],
				checked:false,  //checkbox
				replaceFields:{
					title:'name',
					key:'id'
				},
				checkStrictly:true,
				halfCheckedKeys:[],
				rid:'',  //角色id 
				top_ids:[],   //半节点
				floor_ids:[],  //全节点
			}
		},
		watch: {
		    checkedKeys(val) {
				console.log('onCheck', val)
				//this.checked = e.target.checked
				
				this.checkedKeys = val
		    }
		},
		created(){
			this.rid = getUrlKey('rid')
			if(this.rid){
				this.getNodes()
			}
			this.getMenuList()
		},
		methods: { 
			getNodes(){
				let params = {
					gid:this.rid
				}
				getNodes(params).then(res =>{
					if(res.code==200){
						this.checkedKeys = res.data.floor_ids
						this.halfCheckedKeys = res.data.top_ids
						this.expandedKeys = res.data.floor_ids.concat(res.data.top_ids)
					}
				}).catch(res =>{
					
				})
			},
			onChange(e){  //获取checkbox
				this.checked = e.target.checked
				if(this.checked){
					let arr = this.selectAll(this.treeData,[])
					this.checkedKeys = arr
				}else{
					this.checkedKeys = []
				}
			},
			cancel(){     //表单取消
				this.$router.go(-1)
			},
			getMenuList(){
				let params = { }
				menuList(params).then(res =>{
					if(res.code==200){
						this.treeData = res.data
					}
				}).catch(res =>{
					
				})
			},
			selectAll(data,arr){
				//console.log('data',data)
				for(let i in data){
					//console.log('i',data[i].id)
					arr.push(data[i].id)
					if(data[i].children){
						this.selectAll(data[i].children,arr)
					}
				}
				return arr
			},
			handleSubmit(e) {
			    e.preventDefault();
			    let that = this
				//console.log(this.checkedKeys,this.halfCheckedKeys)
				if(this.checkedKeys.length==0){
					this.$message.error('请选择权限')
					return false
				}
				let params = {
					uid:'',
					gid:this.rid,
					top_ids:this.halfCheckedKeys.join(','),
					floor_ids:this.checkedKeys.join(',')
				}
				bindAuth(params).then(res =>{
					if(res.code==200){
						this.$message.success(res.msg)
						this.getNodes()
						this.updateMune()
						setTimeout(_=>{
							this.$router.go(-1)
						},600)
					}
				}).catch(res=>{
					
				})
			},
			updateMune(){
				store
					.dispatch('GetInfo')
					.then(res => {
						const roles = res.data
						store.dispatch('GenerateRoutes', {
							roles
						}).then(() => {
							router.addRoutes(store.getters.addRouters)
						})
					})
					.catch((res) => {
						notification.error({
							message: '错误',
							description: res.msg
						})
						// 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
						store.dispatch('Logout').then(() => {
							next({
								path: loginRoutePath,
								query: {
									redirect: to.fullPath
								}
							})
						})
					})
			},
			onExpand(expandedKeys) {
				//console.log('onExpand', expandedKeys)
				// if not set autoExpandParent to false, if children expanded, parent can not collapse.
				// or, you can remove all expanded children keys.
				this.expandedKeys = expandedKeys
				this.autoExpandParent = false
			},
			onCheck(checkedKeys,event) {
				//console.log('onCheck', checkedKeys,event.halfCheckedKeys)
				this.checkedKeys = checkedKeys
				this.halfCheckedKeys = event.halfCheckedKeys
				console.log('checkedKeys=',checkedKeys)
				console.log('halfCheckedKeys=',event.halfCheckedKeys)
			},
			onSelect(selectedKeys, info) {
				//console.log('onSelect', info)
				this.selectedKeys = selectedKeys
			}
		},
		watch: {
			
		}
	}
</script>


<style>
.menuTree ul .ant-tree-child-tree{
	display: flex;
	align-items: center;
}
.menuTree ul .ant-tree-child-tree li:not(:last-child)::before{
	content:unset
}
.menuTree .ant-tree-child-tree .anticon-file{
	display: none;
}
</style>